import React from 'react';
import { graphql } from 'gatsby'
import { css } from '@emotion/react';
import { setName } from '../extra/util';
import SEO from '../components/seo';

export default function FourZeroFour({ data }) {
  setName('404');

  return (
    <>
      <div
        className="type--50"
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--orange);
          padding: var(--margin);
          font-family: 'West Space', 'Building Block', sans-serif !important;
        `}
      >
        <div
          css={css`
            margin-top: calc(-1 * var(--largeMargin));
          `}
        >Page not found.</div>
      </div>
    </>
  );
}

export function Head({ data }) {
  return <SEO title={`West Space – 404`} />
}
